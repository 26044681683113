import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/app/appActions';
import { useSnackbar } from 'notistack';

const axiosRef = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosRef.interceptors.request.use(function (config: any) {
  const endpoint = config.url ?? '';
let token = '';

if (endpoint.includes('confirm-email')) {
  token = localStorage.getItem('resendEmailToken') ?? '';
} else {
  token = localStorage.getItem('authToken') ?? '';
}
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
type NestedObject = { [key: string]: any };
function getLastArrayItems(obj: NestedObject): any | null {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      return `${key ? `${key}: ` : ''}${obj[key]}`;
    } else if (typeof obj[key] === 'object') {
      return getLastArrayItems(obj[key]);
    }
  }
  return null;
}
export const AxiosInterceptor = ({ children }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    const resInterceptor = (response: any) => {
      if (response?.status === 401) {
        localStorage.setItem('authToken', '');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessExpiry');
        dispatch(logout());
        history('/login');
      }

      return response;
    };
    const errInterceptor = (error: any) => {
      if (error?.response?.status === 401) {
        localStorage.setItem('authToken', '');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessExpiry');
        dispatch(logout());
        history('/login');
      }

      const errorObject = error?.response?.data?.error;
      if (typeof errorObject === 'string') {
        enqueueSnackbar(errorObject, {
          variant: 'error',
        });
      } else if (errorObject && Object.keys(errorObject).length > 0) {
        let errorText = getLastArrayItems(errorObject);
        // errorText &&
        //   errorText.map((err: string) =>
        //     enqueueSnackbar(err, {
        //       variant: 'error',
        //     })
        //   );

        enqueueSnackbar(
          errorText ? errorText : error?.response?.data?.error?.message,
          {
            variant: 'error',
          }
        );
      } else {
        // If the error structure is not as expected, show a generic error message
        let errorText = error?.response?.data?.error?.message;
        enqueueSnackbar(errorText || 'Error', {
          variant: 'error',
        });
      }

      return Promise.reject(error);
    };

    const interceptor = axiosRef.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => axiosRef.interceptors.response.eject(interceptor);
  }, [history]);
  return children;
};

export { axiosRef };
