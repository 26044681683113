export interface APP_DATA_STATE {
  userToken: string;
  countriesList: any;
  provincesList: any;
  userData: any;
  currentModule: any;
  hasSeenTerms: boolean;
}

export const defaultAppUserDataState: APP_DATA_STATE = {
  currentModule: '',
  userToken: '',
  countriesList: [],
  provincesList: [],
  userData: {},
  hasSeenTerms: false,
};
