import React from 'react';

function MainLoader() {
  return (
    <div className='loader-container'>
      <div className='spinner'></div>
    </div>
  );
}

export default MainLoader;
