const NewAlert = () => {
  return (
    <main className='flex-shrink-0 fixed-top'>
      <div className='alert new-alert-danger text-center' role='alert'>
        <p className='mb_0'>
          As of 12:00 am on November 15th.  Canada post workers are on strike, causing delays in shipment deliveries. We recommend using other courier options on MyBBE for timely shipping.
          <br />
          If you have any questions. please do not hesitate to contact us at customerservice@bbe.ca. and we would be very happy to assist you.
        </p>
      </div>
      <div
        className='position-fixed top-0 end-0 p-3'
        id='toast_list_block'
      ></div>
    </main>
  );
};

export default NewAlert;
  