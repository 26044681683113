import { AbilityBuilder, Ability } from '@casl/ability';

function defineAbilitiesFor(role: string, permissions: any) {
  const { can, cannot, build } = new AbilityBuilder(Ability);
  if (role && permissions.length > 0) {
    for (let i = 0; i < permissions.length; i++) {
      can([`${permissions[i].action}`], `${permissions[i].subject}`);
    }
  } else {
    // cannot('manage', 'all');
  }

  return build();
}

export default defineAbilitiesFor;
