import appDataSlice from './appSlice';

const { actions } = appDataSlice;

export const setCountriesList =
  (countriesList: any) => async (dispatch: any) => {
    dispatch(actions.setCountriesList({ countriesList }));
  };
export const setProvincesList =
  (provincesList: any) => async (dispatch: any) => {
    dispatch(actions.setProvincesList({ provincesList }));
  };
export const setTokenofApp = (token: string) => async (dispatch: any) => {
  localStorage.setItem('authToken', token);
  dispatch(actions.setTokenofApp({ token }));
};
export const setUserData = (userData: any) => async (dispatch: any) => {
  dispatch(actions.setUserData({ userData }));
};
export const setModule = (currentModule: any) => async (dispatch: any) => {
  dispatch(actions.setModule({ currentModule }));
};
export const setHasSeenTerms =
  (hasSeenTerms: boolean) => async (dispatch: any) => {
    dispatch(actions.setHasSeenTerms({ hasSeenTerms }));
  };
export const logout = () => (dispatch: any) => {
  dispatch(actions.logout({}));
};
