import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import localStorage from 'redux-persist/es/storage';
import appDataSlice from './app/appSlice';
import store from './store';

const appDataPersistConfig = {
  key: 'appData',
  storage: localStorage,
};

const rootReducer = combineReducers({
  mainAppData: persistReducer(appDataPersistConfig, appDataSlice.reducer),
});
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof store.dispatch>;
export default rootReducer;
