export const sideBarData = [
  {
    title: 'General',
    data: [
      {
        label: 'My BBE',
        url: '/app/bbe',
        className: 'bi bi-file-bar-graph-fill',
        subject: 'My BBE Page',
        slug: 'mybbe',
      },
      {
        label: 'Dashboard',
        url: '/app/dashboard',
        className: 'bi bi-file-bar-graph-fill',
        subject: 'Dashboard',
        slug: 'dashboard',
      },
      {
        label: 'Quote',
        url: '/app/quick-quote',
        className: 'bi bi-receipt',
        subject: 'Quote',
        slug: 'quote',
      },
      {
        label: 'Ship',
        url: '/app/ship-now',
        className: 'bi bi-box2-fill',
        subject: 'Ship',
        slug: 'ship',
      },
      {
        label: 'Add Leg',
        url: '/app/add-leg',
        className: 'bi bi-box2-fill',
        subject: 'Add Leg',
        slug: 'addLeg',
      },
      {
        label: 'Track',
        url: '/app/track',
        className: 'bi bi-pin-map-fill',
        subject: 'Track',
        slug: 'track',
      },
      {
        label: 'Project',
        url: '/app/project',
        className: 'bi bi-briefcase',
        subject: 'Project',
        slug: 'project',
      },
      {
        label: 'Job History',
        url: '/app/job-history',
        className: 'bi bi-briefcase',
        subject: 'Job History',
        slug: 'job',
      },
      {
        label: 'Shipments History',
        url: '/app/shipment-history',
        className: 'bi bi-list',
        subject: 'Shipments',
        slug: 'shipments',
      },
      {
        label: 'Legs History',
        url: '/app/leg-history',
        className: 'bi bi-list',
        subject: 'Legs',
        slug: 'legs',
      },
      {
        label: 'Overdue Legs',
        url: '/app/overdue',
        className: 'bi bi-calendar-event-fill',
        subject: 'Overdue',
        slug: 'overdue',
      },
      {
        label: 'Reports',
        url: '/app/reports',
        className: 'bi bi-file-text-fill',
        subject: 'Reports',
        slug: 'reports',
      },
      {
        label: 'Dangerous Goods',
        url: '/app/dangerous-goods',
        className: 'bi bi-exclamation-octagon-fill',
        subject: 'Dangerous Goods',
        slug: 'dangerousGoods',
      },
    ],
  },
  {
    title: 'Quotes',
    data: [
      {
        label: 'Saved Quotes',
        url: '/app/saved-quotes',
        className: 'bi bi-book-fill',
        subject: 'Saved Quotes',
        slug: 'savedQuotes',
      },
      {
        label: 'Profiles',
        url: '/app/quotes/profiles',
        className: 'bi bi-book-fill',
        subject: 'Profiles',
        slug: 'profiles',
      },
      {
        label: 'Terms',
        url: '/app/quotes/terms',
        className: 'bi bi-book-fill',
        subject: 'Terms',
        slug: 'terms',
      },
      {
        label: 'Fees',
        url: '/app/quotes/fees',
        className: 'bi bi-book-fill',
        subject: 'Fees',
        slug: 'fees',
      },
    ],
  },
  {
    title: 'Books',
    data: [
      {
        label: 'Addresses',
        url: '/app/saved/address',
        className: 'bi bi-building',
        subject: 'Saved Address',
        slug: 'addresses',
      },
      {
        label: 'Contacts',
        url: '/app/saved/contacts',
        className: 'bi bi-file-earmark-person-fill',
        subject: 'Saved Contact',
        slug: 'contacts',
      },
      {
        label: 'Brokers',
        url: '/app/saved/brokers',
        className: 'bi bi-file-earmark-person-fill',
        subject: 'Saved Broker',
        slug: 'brokers',
      },
      {
        label: 'Packages',
        url: '/app/saved/package',
        className: 'bi bi-boxes',
        subject: 'Saved Packages',
        slug: 'packages',
      },
    ],
  },
  {
    title: 'WAREHOUSE',
    data: [
      {
        label: 'Line Item',
        url: '/app/warehouse/line-item',
        className: 'bi bi-building',
        subject: 'LMS Line Items',
        slug: 'lmsLineItem',
      },
      {
        label: 'Items',
        url: '/app/warehouse/items',
        className: 'bi bi-file-earmark-person-fill',
        subject: 'LMS Items',
        slug: 'lmsItems',
      },
      {
        label: 'Manifest',
        url: '/app/warehouse/manifest',
        className: 'bi bi-file-earmark-person-fill',
        subject: 'LMS Manifest',
        slug: 'lmsManifest',
      },
      // {
      //   label: 'Consolidation',
      //   url: '/app/warehouse/consolidation',
      //   className: 'bi bi-boxes',
      //   subject: 'Admin Dashboard',
      //   slug: 'lmsConsolidation',
      // },
      {
        label: 'Reports',
        url: '/app/warehouse/reports',
        className: 'bi bi-boxes',
        subject: 'LMS Reports',
        slug: 'lmsReports',
      },
    ],
  },
  {
    title: 'Admin',
    data: [
      {
        admin: true,
        label: ' Dashboard',
        url: '/app/admin/dashboard',
        className: 'bi bi-file-bar-graph-fill',
        subject: 'Admin Dashboard',
        slug: 'adminDashboard',
      },
      {
        admin: true,
        label: ' Job History',
        url: '/app/admin/job-history',
        className: 'bi bi-briefcase',
        subject: 'Admin Job History',
        slug: 'job',
      },
      {
        admin: true,

        label: ' Shipments History',
        url: '/app/admin/shipment-history',
        className: 'bi bi-list',
        subject: 'Admin Shipments',
        slug: 'adminShipments',
      },
      {
        admin: true,

        label: ' Legs History',
        url: '/app/admin/leg-history',
        className: 'bi bi-list',
        subject: 'Admin Legs',
        slug: 'adminLegs',
      },
      {
        admin: true,

        label: ' Saved Quotes',
        url: '/app/admin/saved-quotes',
        className: 'bi bi-book-fill',
        subject: 'Admin Saved Quotes',
        slug: 'adminSavedQuotes',
      },
      {
        admin: true,

        label: ' Overdue Legs',
        url: '/app/admin/overdue',
        className: 'bi bi-calendar-event-fill',
        subject: 'Admin Overdue',
        slug: 'adminOverdue',
      },
      {
        admin: true,

        label: ' Reports',
        url: '/app/admin/reports',
        className: 'bi bi-file-text-fill',
        subject: 'Admin Reports',
        slug: 'adminReports',
      },
      {
        admin: true,

        label: ' Manual Shipments',
        url: '/app/admin/manual-shipments',
        className: 'bi bi-truck',
        subject: 'Admin Manual Shipment',
        slug: 'adminManualShipment',
      },
      {
        admin: true,

        label: ' Package Types',
        url: '/app/admin/package_types',
        className: 'bi bi-box-seam',
        subject: 'Admin Package Types',
        slug: 'adminPackageTypes',
      },
      {
        admin: true,

        label: ' Webhooks',
        url: '/app/admin/webhooks',
        className: 'bi bi-file-arrow-up-fill',
        subject: 'Admin Webhooks',
        slug: 'adminWebhooks',
      },
    ],
  },
  {
    title: 'Accounts',
    data: [
      {
        label: 'Accounts',
        url: '/app/admin/accounts',
        className: 'bi bi-people-fill',
        subject: 'Admin Accounts',
        slug: 'accounts',
      },
      {
        label: 'Markups',
        url: '/app/admin/markups',
        className: 'bi bi-bank2',
        subject: 'Admin Markups',
        slug: 'accountsMarkups',
      },
      {
        label: 'Exchange Rates',
        url: '/app/admin/exchange_rates',
        className: 'bi bi-currency-exchange',
        subject: 'Admin Exchange Rates',
        slug: 'accountsExchangeRates',
      },
      {
        label: 'Promo Codes',
        url: '/app/admin/promo_codes',
        className: 'bi bi-qr-code',
        subject: 'Admin Promo Codes',
        slug: 'accountsPromoCodes',
      },
    ],
  },
  {
    title: 'Metrics',
    data: [
      {
        label: 'Account Stats',
        url: '/app/admin/accounts/stats',
        className: 'bi bi-clipboard-data-fill',
        subject: 'Admin Acount Stats',
        slug: 'metricsAccountStats',
      },
      {
        label: 'Shipment Breakdown',
        url: '/app/admin/metrics/breakdown',
        className: 'bi bi-bank2',
        subject: 'Admin Shipment Breakdown',
        slug: 'metricsShipmentBreakdown',
      },
    ],
  },
  {
    title: 'Carrier',
    data: [
      {
        label: 'Carriers',
        url: '/app/admin/carriers',
        className: 'bi bi-truck',
        subject: 'Admin Carriers',
        slug: 'carriers',
      },
      {
        label: 'Fuel Surcharge',
        url: '/app/admin/fuel_surcharge',
        className: 'bi bi-droplet-fill',
        subject: 'Admin Fuel Surcharge',
        slug: 'carriersFuelSurcharge',
      },
      {
        label: 'Pickup Restrictions',
        url: '/app/admin/carrier/pickup_restriction',
        className: 'bi bi-exclamation-triangle-fill',
        subject: 'Admin Pickup Restrictions',
        slug: 'carriersPickupRestrictions',
      },
      {
        label: 'Rate Sheet',
        url: '/app/admin/rate_sheets',
        className: 'bi bi-file-spreadsheet-fill',
        subject: 'Admin Ratesheets',
        slug: 'carriersRatesheets',
      },
      {
        label: 'Ports',
        url: '/app/admin/ports',
        className: 'bi bi-flag-fill',
        subject: 'Admin Port',
        slug: 'carriersPort',
      },
      {
        label: 'Sailing Date',
        url: '/app/admin/sailing_dates',
        className: 'bi bi-file-text-fill',
        subject: 'Admin Sailings',
        slug: 'carriersSailings',
      },
      {
        label: 'Set Lanes',
        url: '/app/admin/set-lanes',
        className: 'bi bi-file-arrow-up-fill',
        subject: 'Admin Set Lanes',
        slug: 'adminSetLanes',
      },
    ],
  },
  {
    title: 'Dev',
    data: [
      {
        label: 'Apis',
        url: '/app/admin/api',
        className: 'bi bi-gear-wide-connected',
        subject: 'Dev Apis',
        slug: 'devApis',
      },
      {
        label: 'Error Codes',
        url: '/app/admin/error',
        className: 'bi bi-bug-fill',
        subject: 'Dev Error Codes',
        slug: 'devErrorCodes',
      },
      {
        label: 'Permissions',
        url: '/app/admin/permissions',
        className: 'bi bi-house-fill',
        subject: 'Dev Permissions',
        slug: 'devPermissions',
      },
      {
        label: 'Tiers',
        url: '/app/admin/tiers',
        className: 'bi bi-file-text-fill',
        subject: 'Dev Tiers',
        slug: 'devTiers',
      },
    ],
  },
];
