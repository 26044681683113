import { createSlice } from '@reduxjs/toolkit';
import { APP_DATA_STATE, defaultAppUserDataState } from './appInterfaces';

const initialApplicationState: APP_DATA_STATE = {
  ...defaultAppUserDataState,
};

const appDataSlice = createSlice({
  name: 'appData',
  initialState: initialApplicationState,
  reducers: {
    setCountriesList: (state, action) => {
      state.countriesList = action.payload.countriesList;
    },
    setProvincesList: (state, action) => {
      state.provincesList = action.payload.provincesList;
    },
    setTokenofApp: (state, action) => {
      state.userToken = action.payload.token;
    },
    setUserData: (state, action) => {
      state.userData = action.payload.userData;
    },
    logout: (state, action) => {
      state.userToken = '';
      state.countriesList = null;
      state.provincesList = null;
      state.userData = null;
      state.currentModule = '';
      state.hasSeenTerms = false;
    },
    setModule: (state, action) => {
      state.currentModule = action.payload.currentModule;
    },
    setHasSeenTerms: (state, action) => {
      state.hasSeenTerms = action.payload.hasSeenTerms;
    },
  },
});

export default appDataSlice;
