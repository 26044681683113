import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../assets/icons/ubbe_logo/ubbe_logo.svg';
import LogoMin from '../../assets/icons/ubbe_logo/ubbe_logo_min.svg';
import { sideBarData } from './sidebarData';
import { useSelector } from 'react-redux';
import defineAbilitiesFor from '../../utils/ability';
import { RootState } from '../../redux/rootReducer';
import { setModule } from '../../redux/app/appActions';
import { useDispatch } from 'react-redux';
import './sidebar.scss';
import { Icons } from './Icons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import emptyFolder from '../../assets/icons/empty-folder.png';
import cancel from '../../assets/icons/new_icon/tag-cross.svg';
import { Link } from 'react-router-dom';

interface Props {
  setOpenSideBar: any;
}

function SideBar({ setOpenSideBar }: Props) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarRef: any = useRef(null);
  const [minimized, setMinimized] = useState(true);

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpenSideBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenSideBar]);

  const handleClick = (url: string) => {
    setOpenSideBar(false);
    window.scrollTo(0, 0);
    // navigate(url);
  };
  const { userData } = useSelector((state: RootState) => state.mainAppData);
  const userRole = userData?.user_role_permissions[0]?.role;
  const ability = defineAbilitiesFor(userRole, userData?.user_role_permissions);
  const [searchInput, setSearchInput] = useState('');

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInput(event.target.value);
  };

  const filteredSideBarData = sideBarData
    .map((section, index) => ({
      ...section,
      data: section.data.filter(
        (item) =>
          ability.can('read', item.subject) ||
          ability.can('create', item.subject)
      ),
    }))
    .filter((section) => section.data.length > 0);

  const filteredItems = filteredSideBarData.reduce((acc, section) => {
    const matchingItems = section.data.filter((item) =>
      item.label.toLowerCase().includes(searchInput.toLowerCase())
    );
    if (matchingItems.length > 0) {
      acc.push({
        ...section,
        data: matchingItems,
      });
    }
    return acc;
  }, [] as typeof filteredSideBarData);
  const handleToggleMinimize = () => {
    setMinimized((prevMinimized) => !prevMinimized);
    setSearchInput('');
  };

  const getActiveItem = (item: any) => {
    const currentPath = location.pathname;
    if (currentPath === '/app/admin/accounts/stats') {
      return currentPath === item.url ? 'active' : '';
    } else if (
      currentPath === '/app/quote/saved/view' &&
      item.url === '/app/saved-quotes'
    ) {
      return 'active';
    } else if (
      currentPath === '/app/admin/quote/saved/view' &&
      item.url === '/app/admin/saved-quotes'
    ) {
      return 'active';
    } else if (
      currentPath === '/app/admin/user/add' &&
      item.url === '/app/admin/accounts'
    ) {
      return 'active';
    }
    return currentPath.includes(item.url) ? 'active' : '';
  };
  return (
    <>
      {!minimized && (
        <div className='sidebar-backdrop' onClick={handleToggleMinimize}></div>
      )}
      <div
        className={`fixed-top-banner fixed-bottom bg-white shadow-sm sidebar__main  ${
          minimized ? 'minimized' : 'show'
        }`}
        tabIndex={-1}
        style={{
          width: minimized ? '6.7rem' : '16rem',
        }}
        ref={sidebarRef}
      >
        <div>
          <p
            className='cursor_pointer mb_0 d-grid'
            onClick={() => {
              navigate('/app/dashboard');
              setOpenSideBar(false);
            }}
          >
            <img
              className={`${minimized ? 'sidebar__logo-min' : 'sidebar__logo'}`}
              src={minimized ? LogoMin : Logo}
              style={{}}
              alt='ubbe logo'
            />
          </p>
        </div>
        <div>
          <div
            className={`sidebar__search-container ${
              minimized ? 'sidebar__search-container-min' : ''
            }`}
            style={{}}
          >
            <div
              className={`d-flex align-items-center ${
                minimized ? 'justify-content-center ' : ''
              }`}
              style={{}}
            >
              <div
                className='cursor_pointer'
                onClick={() => {
                  if (minimized) {
                    handleToggleMinimize();
                  }
                }}
                style={{}}
              >
                {Icons?.search()}
              </div>
              {!minimized && (
                <input
                  className='no-border-input outline-search-input sidebar__search-input'
                  placeholder='Search anything here'
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
              )}
              {searchInput && !minimized && (
                <span
                  className='input-group-text searchIcon cursor_pointer'
                  id='button-addon2'
                  style={{ marginLeft: '-50px' }}
                  onClick={() => setSearchInput('')}
                >
                  <img src={cancel} alt={searchInput} />
                </span>
              )}
            </div>
            <span
              className={`d-flex  sidebar__toggle bg-white p-1 rounded-circle cursor_pointer ${
                minimized ? 'sidebar__toggle-min' : 'sidebar__toggle-min'
              }`}
              style={{
                left: minimized ? '5.775rem' : '15rem', // Adjust left position when minimized
              }}
              onClick={handleToggleMinimize}
            >
              {minimized ? Icons?.open() : Icons?.close()}
            </span>
          </div>
          <div className='sidebar__scroll'>
            {filteredItems.length > 0
              ? filteredItems.map((section, index) => (
                  <div key={index}>
                    <div
                      id={`section_${index}`}
                      className='sidebar__nav-section'
                    >
                      {section.title && (
                        <label
                          className={`sidebar__nav-title d-flex ${
                            minimized
                              ? 'justify-content-center'
                              : 'justify-content-start'
                          }`}
                        >
                          {section.title}
                        </label>
                      )}
                      <ul className='nav nav-pills flex-column mb-auto'>
                        {section.data.map((item, itemIndex) => (
                          <li
                            key={itemIndex}
                            onClick={() => setMinimized(true)}
                          >
                            <Link
                              className={`sidebar__nav-item my-auto cursor_pointer mb_0 ${getActiveItem(
                                item
                              )} ${
                                minimized
                                  ? 'justify-content-center'
                                  : 'justify-content-start'
                              }`}
                              onClick={() => {
                                handleClick(item.url);
                                setSearchInput('');
                                dispatch(setModule(item?.label));
                              }}
                              to={item.url}
                            >
                              {Icons[item?.slug] ? (
                                minimized ? (
                                  <OverlayTrigger
                                    placement='right'
                                    delay={{ show: 150, hide: 200 }}
                                    overlay={(props) => (
                                      <Tooltip id='button-tooltip' {...props}>
                                        {item.label}
                                      </Tooltip>
                                    )}
                                  >
                                    {Icons[item?.slug](
                                      getActiveItem(item) === 'active'
                                    )}
                                  </OverlayTrigger>
                                ) : (
                                  <div style={{ width: '24px' }}>
                                    {Icons[item?.slug](
                                      getActiveItem(item) === 'active'
                                    )}
                                  </div>
                                )
                              ) : null}
                              {!minimized && item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {index < filteredItems.length - 1 && (
                      <hr className='my-3' />
                    )}
                  </div>
                ))
              : !minimized && (
                  <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                    <img alt='empty-folder' src={emptyFolder} className='' />
                    <p className='sidebar__search-empty mt-2'>
                      No Results Found
                    </p>
                  </div>
                )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
